// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~chart.js/dist/Chart.min.css';
//@import '~bootstrap4-toggle/css/bootstrap4-toggle.min.css';

@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';
@import 'datepicker.css';
@import '~select2/src/scss/core';
@import '~@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.min.css';

.nemanista  #notfound {
    position: relative;
    //height: 100vh
    height: 700px;
}

.nemanista #notfound .notfound {
    position: absolute;
    left: 50%;
    top: 20%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
}

.nemanista  .notfound {
    max-width: 520px;
    width: 100%;
    line-height: 1.4;
    text-align: center
}

.nemanista  .notfound .notfound-404 {
    position: relative;
    height: 240px
}

.nemanista  .notfound .notfound-404 h1 {
    font-family: montserrat, sans-serif;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 252px;
    font-weight: 900;
    margin: 0;
    color: #262626;
    text-transform: uppercase;
    letter-spacing: -40px;
    margin-left: -20px
}

.nemanista  .notfound .notfound-404 h1 > span {
    text-shadow: -8px 0 0 #fff
}

.nemanista .notfound .notfound-404 h3 {
    font-family: cabin, sans-serif;
    position: relative;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: #262626;
    margin: 0;
    letter-spacing: 3px;
    padding-left: 6px
}

.nemanista .notfound h2 {
    font-family: cabin, sans-serif;
    font-size: 20px;
    font-weight: 400;
    text-transform: uppercase;
    color: #000;
    margin-top: 0;
    margin-bottom: 25px
}

@media only screen and (max-width: 767px) {
    .nemanista  .notfound .notfound-404 {
        height: 200px
    }

    .nemanista  .notfound .notfound-404 h1 {
        font-size: 200px
    }
}

@media only screen and (max-width: 480px) {
    .nemanista .notfound .notfound-404 {
        height: 162px
    }

    .nemanista .notfound .notfound-404 h1 {
        font-size: 162px;
        height: 150px;
        line-height: 162px
    }

    .nemanista .notfound h2 {
        font-size: 16px
    }
}

